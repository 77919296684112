import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Logo from "../components/logo"

const Content = styled.div`
  max-width: 500px;
  max-height: 640px;
  background-color: #ffffffe0;
  padding: 2rem;
  text-align: center;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Balgaling Views - Property Toodyay" />
    <Image />
    <Content>
      <Logo />

      <p>
        Land with rolling green hills, running winter streams, bright blue skies
        and dazzling starlights are the stuff of dreams.
      </p>
      <p>New land releases of 5 to 10 acres available now</p>
      <div>
        <strong>Contact us</strong>
      </div>
      <p>
        John Butler – 0429 964 060 <br /> toodyay@ljh.com.au{" "}
      </p>

      <p>Michelle Allardyce – 0417 934 938 michelle.allardyce@elders.com.au</p>
    </Content>
  </Layout>
)

export default IndexPage
